<template>
    <div>
        <div>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--8">
                <div class="card">
                    <div class="card-header border-0">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('good_issued_pivot')}}</h3>
                            </div>
                            <div class="col">
                                <base-button type="default" size="sm" class="float-right" @click="generate" :disabled="(table.load && table.loadGenerate)">{{ tt('generate')}} <i class="fas fa-spinner fa-spin" v-if="(table.load && table.loadGenerate)"></i></base-button>
                                <base-button type="default" size="sm" class="float-right mr-2" @click="handleFilter">{{ tt('filter')}}</base-button>
                                <!-- <el-dropdown trigger="click" class="float-right mr-2" @command="handleCommand">
                                    <span class="el-dropdown-link btn btn-dark btn-sm">
                                        {{ selectedYear }} <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="year in years" :key="year.year" :command="year.year">{{ year.year }}</el-dropdown-item>
                                        <el-dropdown-item command="All">All</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown> -->

                            </div>
                            <!-- <div class="col text-right">
                                <base-button type="default" size="sm" @click="" :disabled="load.transaction_history">{{tt('save')}} <span v-if="load.transaction_history"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                            </div> -->
                        </div>
                    </div>

                    <div style="height:500px;overflow-y:auto;overflow-x:auto;" v-if="filter.type == 'Year'">
                        <div v-if="!table.load">
                            <table class="table table-bordered">
                                <thead class="bg-primary">
                                    <tr>
                                        <th rowspan="2" style="vertical-align:middle" class="text-white">{{tt('material_number')}}</th>
                                        <th colspan="12" class="text-center text-white">{{tt('month')}}</th>
                                        <th rowspan="2" style="vertical-align:middle" class="text-white">{{ tt('sum_of_process')}}</th>
                                    </tr>
                                    <tr>
                                        <th v-for="month in months" :key="month" class="text-white">{{ month }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="data in dataByYear" :key="data.material_number">
                                        <td>{{ data.material_number}}</td>
                                        <td v-for="month in data.data">{{ month.data.sum_of_qty }}</td>
                                        <td>{{ data.sum_of_process }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <skeleton-loading v-else></skeleton-loading>
                    </div>

                    <div style="height:500px;overflow-y:auto;overflow-x:auto;" v-else-if="filter.type == 'Month'">
                        <div v-if="!table.load">
                            <table class="table table-bordered">
                                <thead class="bg-primary">
                                    <tr>
                                        <th rowspan="3" style="vertical-align:middle" class="text-white">{{tt('material_number')}}</th>
                                        <th :colspan="days.length * 2" class="text-center text-white">{{tt('day')}}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2" v-for="day in days" :key="day" class="text-white text-center">{{ day }}</th>
                                    </tr>
                                    <tr>
                                        <template v-for="(key, day) in days">
                                            <th :key="day" class="text-white text-center">{{ tt('sum_of_process')}}</th>
                                            <th :key="day" class="text-white text-center">{{ tt('sum_of_qty')}}</th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="data in dataByMonth" :key="data.material_number">
                                        <td>{{ data.material_number}}</td>
                                       <template>
                                            <td v-for="day in data.data">
                                                <span class="badge badge-success text-primary" v-if="day.sum_of_process != 0">{{ day.sum_of_process }}</span>
                                                <span  v-else>{{ day.sum_of_process }}</span>
                                            </td>
                                            <td v-for="day in data.data">
                                                <span class="badge badge-success text-primary" v-if="day.sum_of_qty != 0">{{ day.sum_of_qty }}</span>
                                                <span  v-else>{{ day.sum_of_qty }}</span>
                                            </td>
                                       </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <skeleton-loading v-else></skeleton-loading>
                    </div>

                    <div style="height:500px;overflow-y:auto;overflow-x:hidden;" v-else>
                        <div class="row" v-if="!table.load">
                            <div class="col-xl-4" v-for="data in table.data" :key="data.year">
                                <div class="container-fluid">
                                    <div class="card">
                                        <div class="card-header border-0">
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <h3 class="mb-0 text-center">{{ data.year }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <!-- <el-table class="table-responsive table" style="width: 100%" :data="data.data" header-row-class-name="thead-light" border>
                                                <el-table-column :label="tt('material_number')" width="190px" sortable  prop="material_number"></el-table-column>
                                                <el-table-column :label="tt('process')" sortable width="100px" prop="sum_of_process"></el-table-column>
                                                <el-table-column :label="tt('qty')" sortable  width="95px" prop="sum_of_qty"></el-table-column>
                                            </el-table> -->
                                            <table class="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>{{ tt('material_number')}}</th>
                                                        <th>{{ tt('process')}}</th>
                                                        <th>{{ tt('qty')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="res in data.data">
                                                        <td>{{ res.material_number }}</td>
                                                        <td>{{ res.sum_of_process }}</td>
                                                        <td>{{ res.sum_of_qty }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <skeleton-loading v-else></skeleton-loading>
                    </div>

                </div>                
            </div>
        </div>

        <modal :show.sync="filterForm" size="md">
            <template slot="header">
                <h-5 class="modal-title">
                    {{ tt('filter')}}
                </h-5>
            </template>
            <label for="" class="form-control-label">{{tt('select_filter')}}</label>
            <base-input :name="tt('type')">
                <el-select v-model="filter.type">
                    <el-option value="Year">{{ tt('year')}}</el-option>
                    <el-option value="Month">{{ tt('month')}}</el-option>
                    <el-option value="All">{{ tt('all')}}</el-option>
                </el-select>
            </base-input>
            <div v-if="filter.type == 'Year'">
                <label for="" class="form-control-label">
                    {{ tt('year') }}
                </label>
                <base-input :name="tt('year')">
                    <el-select v-model="filter.dataYear" @change="resetDateMonth">
                        <el-option v-for="year in years" :value="year.year">{{ year.year}}</el-option>
                    </el-select>
                </base-input>
            </div>
            <div v-else-if="filter.type == 'Month'">
                <label for="" class="form-control-label">
                    {{ tt('year') +' '+tt('month') }}
                </label>
                <base-input :name="tt('month')">
                    <el-date-picker
                    v-model="filter.dataMonth"
                    type="month"
                    placeholder="Select year and month"
                    style="width:100%"
                    format="yyyy-M"
                    value-format="yyyy-M"
                    @change="resetDateYear">
                    </el-date-picker>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="default" size="sm" @click="filterAction">{{ tt('filter')}}</base-button>
            </template>
        </modal>
    </div>
</template>


<script>
import Api from '@/helpers/api';
import GoodIssuedService from '@/services/rcs/goodIssued.service'
export default {
    data(){
        return{
            table:{
                data:[],
                load:false,
                page:{},
                loadGenerate:false
            },
            years:[],
            selectedYear:'Year',
            dataByYear:[],
            months:[],
            filterForm:false,
            filter:{
                type:'',
                dataYear:'',
                dataMonth:''
            },
            databyMonth:[],
            days:[],
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        get(){
            let context = this;
            this.table.load = true
            Api(context, GoodIssuedService.getPivot()).onSuccess((response) => {
                context.table.data = response.data.data.data;
                context.years = response.data.data.years;
                context.selectedYear = 'Year'
            }).onError((err) =>{
                context.table.data = []
                context.years = []
            }).onFinish(() => {
                context.table.load = false
            }).call()
        },
        generate(){
            let context = this;
            this.table.load = true;
            this.table.loadGenerate = true;
            Api(context, GoodIssuedService.generate()).onSuccess((response) => {
                context.get()
            }).onError((err) =>{
                context.$notify({
                    message: err.response.data.message,
                    type: 'danger',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.table.load = false;
                context.table.loadGenerate = false;
            }).call()
        },
        handleCommand(command){
            if(command == 'All'){
                this.get()
                return;
            }
            this.getByYear(command);
        },
        getByYear(year){
            let context = this;
            this.table.load = true;
            Api(context, GoodIssuedService.getPivotYear(year)).onSuccess((response) => {
                context.dataByYear= response.data.data.data;
                context.months = response.data.data.months
            }).onError((err) =>{
                context.dataByYear = []
                context.months = []
            }).onFinish(() => {
                context.table.load = false
                context.selectedYear = year
            }).call()
        },
        getByMonth(date){
            let context = this;
            this.table.load = true;
            let splitDate = date.split('-')
            let year = splitDate[0]
            let month = splitDate[1];

            Api(context, GoodIssuedService.getPivotMonth({ year: year, month:month})).onSuccess((response) => {
                context.dataByMonth= response.data.data.data;
                context.days= response.data.data.days
            }).onError((err) =>{
                context.dataByMonth = []
                context.days = []
            }).onFinish(() => {
                context.table.load = false
            }).call()
        },
        handleFilter(){
            this.filterForm = true;
            this.filter.type = '';
            this.filter.dataYear = '';
            this.filter.dataMonth = '';
        },
        resetDateMonth(){
            this.filter.dataMonth = '';
        },
        resetDateYear(){
             this.filter.dataYear = '';
        },
        filterAction(){
            if(this.filter.type == 'Year'){
                this.getByYear(this.filter.dataYear);
            }else if(this.filter.type == 'Month'){
                this.getByMonth(this.filter.dataMonth)
            }else{
                this.get()
            }
            this.filterForm = false;
        }
        
    }
}
</script>